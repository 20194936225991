<template>
<div class="fili">
    <div class="title">{{isMealCard?'饭卡列表':'档案列表'}}</div>
      <filters @filterChange="filterData" ref="fileFilters" :isMealCard="isMealCard"></filters>
      <div class="fun">
        <search placeholder="请输入姓名、编号" @searchKeyChange="filterData" ref="search"></search>
        <import-export v-if="!isMealCard" @onDeleteFiles="onDeleteFiles"></import-export>
      </div>
      <div class="list">
        <div class="table">
          <a-table
            :row-selection="isMealCard ? null : { selectedRowKeys: selectedRowKeys, onChange: onSelectChange, getCheckboxProps: getCheckboxProps }"
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            bordered
            class="custom-table"
            @change="tableChange"
            rowKey="_id"
            :scroll="{x: isMealCard ? 1460 : 1150}"
          >
            <template v-if="isMealCard" slot="card_num" slot-scope="text, record">
              <a @click="showCardInfo(record)">{{ record.card_num }}</a>
            </template>
            <template
              slot="type"
              slot-scope="text, record"
            >
              <span>{{record.type}}</span>
            </template>
            <template v-if="isMealCard"
              slot="state"
              slot-scope="text, record"
            >
              <span :class="{fred:record.state=='unbind'}">{{bindStates[record.state]}}</span>
            </template>
            <template
              slot="is_disable"
              slot-scope="text, record"
            >
              <span >{{record.is_disable?'禁用':'启用'}}</span>
            </template>
             <template
              slot="operation"
              slot-scope="text, record"
            >
              <a @click="editFile(record)">修改</a>
              <a-divider type="vertical" />
              <a
                @click="deleteFile(record)"
                v-if="record.state=='unbind'"
              >删除</a>
              <a-popconfirm
                title="确定要禁用吗？"
                ok-text="Yes"
                cancel-text="No"
                @confirm="changeEnableState(record, true)"
              >
                <a v-if="record.state!='unbind' && !record.is_disable"
                  class="fprimary">禁用</a>
              </a-popconfirm>
              <a v-if="record.state!='unbind' && record.is_disable"
                class="fgreen"
                @click="changeEnableState(record, false)"
              >启用</a>
            </template>
          </a-table>
        </div>
      </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import importExport from './ImportExport'
import filters from './Filter'
import search from '@/components/common/Search'
import apis from '@/business/apis'
import { all, enableTypes, bindStates } from '@/business/enums'
const columns = [
  {
    title: '编号',
    dataIndex: 'num',
    fixed: 'left',
    width: 200
  },
  {
    title: '姓名',
    dataIndex: 'name',
    fixed: 'left',
    width: 120
  },
  {
    title: '性别',
    dataIndex: 'gender',
    width: 80
  },
  {
    title: '身份',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
    width: 90
  },
  {
    title: '卡号',
    dataIndex: 'card_num',
    scopedSlots: { customRender: 'card_num' }
  },
  {
    title: '子部门',
    dataIndex: 'classes',
    width: 160
  },
  // {
  //   title: '联系人',
  //   dataIndex: 'contact',
  //   width: 120
  // },
  {
    title: '联系方式',
    dataIndex: 'contact_mobile',
    width: 140
  },
  {
    title: '饭卡状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'state' },
    width: 120
  },
  {
    title: '账号状态',
    dataIndex: 'is_disable',
    scopedSlots: { customRender: 'is_disable' },
    width: 100
  },
  {
    title: '操作',
    dataIndex: 'operation',
    fixed: 'right',
    scopedSlots: { customRender: 'operation' },
    width: 110
  }
]

export default {
  inject: ['eventBus'],
  components: {
    filters,
    search,
    importExport
  },
  props: {
    isMealCard: { default: false }
  },
  data () {
    return {
      bindStates,
      selectedRowKeys: [],
      columns: [],
      pagination: {
        current: 1
      },
      data: []
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ]),
    filterCondition () {
      return {
        grade: this.$refs.fileFilters.selectedGrade,
        bindState: this.$refs.fileFilters.selectedBindState,
        userType: this.$refs.fileFilters.selectedUserType,
        enableType: this.$refs.fileFilters.selectedEnableType,
        keyword: this.$refs.search.searchKey
      }
    }
  },
  methods: {
    async filterData () {
      if (!this.$refs.fileFilters) return
      let query = '?schoolId=' + this.currentSchool._id + '&current=' + this.pagination.current
      if (this.filterCondition.grade !== all) {
        query += ('&grade=' + this.filterCondition.grade)
      }
      if (this.filterCondition.bindState !== 'all') {
        query += ('&state=' + this.filterCondition.bindState)
      }
      if (this.filterCondition.userType !== all) {
        query += ('&type=' + this.filterCondition.userType)
      }
      if (this.filterCondition.enableType !== all) {
        query += ('&isDisable=' + (this.filterCondition.enableType === enableTypes.disable))
      }
      if (this.filterCondition.keyword) {
        query += ('&keyword=' + this.filterCondition.keyword)
      }
      const response = await this.$http.get(apis.user.list + query)
      this.data = response.body.list
      this.pagination = response.body.pagination
      this.data.forEach(item => {
        item.classes = item.group || ''
        // if (item.type === userTypes.student) {
        //   item.classes = item.group || ''
        // } else {
        //   item.classes = item.department || ''
        // }
      })
      this.selectedRowKeys = []
    },
    tableChange (pagination, filters, sorter) {
      this.pagination.current = pagination.current
      this.filterData()
    },
    editFile (record) {
      this.eventBus.$emit('show-editfile-modal', record, false)
    },
    deleteFile (record) {
      this.eventBus.$emit('show-deletefile-modal', record, false)
    },
    onDeleteFiles () {
      if (!this.selectedRowKeys.length) {
        this.$message.info('没有选择任何档案')
        return
      }
      this.eventBus.$emit('show-deletefile-modal', this.selectedRowKeys, true)
    },
    async changeEnableState (record, disable) {
      const response = await this.$http.post(apis.user.changeEnableState, { id: record._id, isDisable: disable })
      if (response.body.success) {
        record.is_disable = disable
        this.$message.success('"' + record.name + (disable ? '"已禁用' : '"已启用'))
      }
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    getCheckboxProps (record) {
      return {
        props: {
          disabled: record.state !== 'unbind'
        }
      }
    },
    showCardInfo (record) {
      this.eventBus.$emit('show-carddetail-modal', record.card_num)
    }
  },
  created () {
    this.eventBus.$on('filemanage-reloaddata', this.filterData)
    this.columns = JSON.parse(JSON.stringify(columns))
    if (!this.isMealCard) {
      this.columns.splice(7, 1)
      this.columns.splice(4, 1)
      delete this.columns[4].width
    }
  },
  beforeDestroy () {
    this.eventBus.$off('filemanage-reloaddata', this.filterData)
  }
}
</script>
