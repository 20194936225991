<template>
    <div class="total">
      <a-row>
        <a-col :span="12">
          <div class="item">
            <div class="bd">
              <div class="icon"><img src="~@/assets/images/icon-school4.png" /></div>
              <div class="tenu">
                <div class="text">警员</div>
                <div class="num"><b>{{student.nBinded + student.nUnbind + student.nLost}}</b><span>人</span></div>
              </div>
            </div>
            <div class="fd" v-if="isMealCard">
              <a-row>
                <a-col :span="8">
                  <div class="con">
                    <div class="text">已激活：</div>
                    <div class="num fgreen">{{student.nBinded}}</div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div class="con">
                    <div class="text">未绑定：</div>
                    <div class="num fred">{{student.nUnbind}}</div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div
                    class="con"
                    style="border-right:none"
                  >
                    <div class="text">已挂失：</div>
                    <div class="num">{{student.nLost}}</div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <!-- <a-col :span="8">
          <div class="item">
            <div class="bd">
              <div class="icon"><img src="~@/assets/images/icon-school5.png" /></div>
              <div class="tenu">
                <div class="text">教师</div>
                <div class="num"><b>{{teacher.nBinded + teacher.nUnbind + teacher.nLost}}</b><span>人</span></div>
              </div>
            </div>
            <div class="fd" v-if="isMealCard">
              <a-row>
                <a-col :span="8">
                  <div class="con">
                    <div class="text">已激活：</div>
                    <div class="num fgreen">{{teacher.nBinded}}</div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div class="con">
                    <div class="text">未绑定：</div>
                    <div class="num fred">{{teacher.nUnbind}}</div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div
                    class="con"
                    style="border-right:none"
                  >
                    <div class="text">已挂失：</div>
                    <div class="num">{{teacher.nLost}}</div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col> -->
        <a-col :span="12">
          <div class="item">
            <div class="bd">
              <div class="icon"><img src="~@/assets/images/icon-school6.png" /></div>
              <div class="tenu">
                <div class="text">其他</div>
                <div class="num"><b>{{other.nBinded + other.nUnbind + other.nLost}}</b><span>人</span></div>
              </div>
            </div>
            <div class="fd" v-if="isMealCard">
              <a-row>
                <a-col :span="8">
                  <div class="con">
                    <div class="text">已激活：</div>
                    <div class="num fgreen">{{other.nBinded}}</div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div class="con">
                    <div class="text">未绑定：</div>
                    <div class="num fred">{{other.nUnbind}}</div>
                  </div>
                </a-col>
                <a-col :span="8">
                  <div
                    class="con"
                    style="border-right:none"
                  >
                    <div class="text">已挂失：</div>
                    <div class="num">{{other.nLost}}</div>
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
</template>
<script>
import apis from '@/business/apis'
import { mapState } from 'vuex'
export default {
  inject: ['eventBus'],
  props: {
    isMealCard: { default: false }
  },
  data () {
    return {
      student: { nBinded: 0, nUnbind: 0, nLost: 0 },
      teacher: { nBinded: 0, nUnbind: 0, nLost: 0 },
      other: { nBinded: 0, nUnbind: 0, nLost: 0 }
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ])
  },
  methods: {
    async loadData () {
      const query = '?schoolId=' + this.currentSchool._id
      const respose = await this.$http.get(apis.user.statistics + query)
      this.student = respose.body.student
      this.teacher = respose.body.teacher
      this.other = respose.body.other
    }
  },
  created () {
    this.loadData()
    this.eventBus.$on('filemanage-reloaddata', this.loadData)
  },
  beforeDestroy () {
    this.eventBus.$off('filemanage-reloaddata', this.loadData)
  }
}
</script>
