<template>
<a-modal
      :visible="showModal"
      class="customermodel1"
      width="400px"
      :centered="true"
      :footer="false"
      :closable="false"
      :maskClosable="true"
    >
      <div class="con delinfo-model">
        <div class="icte">
          <div class="icon"><img src="~@/assets/images/icon-del.png" /></div>
          <div class="text">
            <h1>{{delTip}}</h1>
          </div>
        </div>
        <div class="button">
          <a-button
            size="large"
            @click="showModal=false"
          >取消</a-button>
          <a-button
            size="large"
            type="primary"
            @click="clickOk"
          >
            确认
          </a-button>
        </div>
      </div>
</a-modal>
</template>
<script>
import apis from '@/business/apis'
export default {
  inject: ['eventBus'],
  data () {
    return {
      delTip: '',
      data: [],
      showModal: false
    }
  },
  methods: {
    showDeleteModal (data, isArray) {
      this.showModal = true
      if (isArray) {
        this.delTip = '确定删除所选的档案吗?'
        this.data = data
      } else {
        this.delTip = `确定删除【${data.name}】吗?`
        this.data = [data._id]
      }
    },
    async clickOk () {
      await this.$http.post(apis.user.deleteMany, { ids: this.data })
      this.showModal = false
      this.$message.success('删除成功')
      this.eventBus.$emit('filemanage-reloaddata')
    }
  },
  created () {
    this.eventBus.$on('show-deletefile-modal', this.showDeleteModal)
  },
  beforeDestroy () {
    this.eventBus.$off('show-deletefile-modal', this.showDeleteModal)
  }
}
</script>
