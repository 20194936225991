<template>
<a-modal
      :title="isNew?'新增':'修改'"
      :visible="showModal"
      @ok="editOk"
      @cancel="editCancel"
      class="customermodel"
      width="500px"
      :centered="true"
    >
      <div class="editinfo-model">
        <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-item label="姓名">
            <a-input size="large" v-decorator="['name',{rules: [{ required: true, message: '姓名不能为空！' }]}]" />
          </a-form-item>
          <a-form-item label="性别">
            <a-select
              size="large"
              v-model="data.gender"
            >
              <a-select-option value="男">
                男
              </a-select-option>
              <a-select-option value="女">
                女
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="身份">
            <a-select
              size="large"
              @change="typeChange"
              v-decorator="['type',{rules: [{ required: true, message: '身份不能为空！' }]}]"
            >
              <template v-for="item in userTypes">
                <a-select-option :key="item" :value="item" v-if="item!='全部'">{{item}}</a-select-option>
              </template>
            </a-select>
          </a-form-item>
          <a-form-item label="编号">
            <a-input size="large" v-decorator="['num',{rules: [{ required: true, message: '编号不能为空！' }]}]" :disabled="!isNew" />
          </a-form-item>
          <a-form-item label="子部门" > <!-- v-show="data.type===userTypes.student" -->
            <a-select showSearch option-filter-prop="children" size="large" v-decorator="['group_id',{rules: [{ required: true, message: '部门不能为空！' }]}]">
              <template v-for="item in classList">
                <a-select-option :key="item._id" :value="item._id" >{{item.name}}</a-select-option>
              </template>
            </a-select>
          </a-form-item>
          <!-- <a-form-item label="部门" v-show="data.type!==userTypes.student">
            <a-input size="large" v-model="data.department"/>
          </a-form-item> -->
           <!-- <a-form-item label="联系人">
            <a-input size="large" v-model="data.contact"/>
          </a-form-item> -->
          <a-form-item label="联系方式">
            <a-input size="large" v-decorator="['mobile',{rules: [{ required: true, message: '联系方式不能为空！' }]}]"/>
          </a-form-item>
          <a-form-item label="状态" v-if="isMealCard&&!isTeacher&&false">
            <a-select
              size="large"
              v-model="data.state"
            >
              <a-select-option value="unbind">
                未绑定
              </a-select-option>
              <a-select-option value="bound">
                已激活
              </a-select-option>
              <a-select-option value="lost">
                已挂失
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-form>
      </div>
</a-modal>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { userTypes } from '@/business/enums'
import apis from '@/business/apis'
export default {
  inject: ['eventBus'],
  props: {
    isMealCard: { default: false }
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'edit' }),
      userTypes,
      isNew: false,
      data: {},
      classList: [],
      showModal: false
    }
  },
  computed: {
    ...mapState('user', [
      'isTeacher'
    ]),
    ...mapState('school', [
      'currentSchool'
    ]),
    ...mapGetters('school', [
      'schoolGrades'
    ])
  },
  methods: {
    showEditModal (data, isNew) {
      this.data = JSON.parse(JSON.stringify(data))
      this.showModal = true
      this.isNew = isNew
      this.initForm()
    },
    typeChange (value) {
      // this.data.type = value
      // if (this.data.type === userTypes.student) {
      //   this.form.setFields({ group_id: { value: this.data.group_id } })
      // }
    },
    async editOk () {
      // if (this.data.type !== userTypes.student) {
      //   this.form.setFields({ group_id: { value: this.data.group_id || ' ' } })
      // }
      this.form.validateFields((err, values) => {
        if (err) return
        this.data.name = values.name
        this.data.type = values.type
        this.data.num = values.num
        this.data.contact_mobile = values.mobile
        this.data.group_id = values.group_id
        // if (this.data.type === userTypes.student) {
        //   const group = this.classList.filter(item => item._id === this.data.group_id)
        //   if (group[0]) {
        //     this.data.group = group[0].name
        //     this.data.grade = group[0].grade
        //   }
        // } else {
        //   delete this.data.group_id
        //   this.data.group = this.data.grade = ''
        // }
        const group = this.classList.filter(item => item._id === this.data.group_id)
        if (group[0]) {
          this.data.group = group[0].name
          this.data.grade = group[0].grade
        }
        if (this.isNew) {
          this.addStudent()
        } else {
          this.modifyStudent()
        }
      })
    },
    async modifyStudent () {
      const response = await this.$http.post(apis.user.modify + this.data._id, this.data)
      this.showModal = false
      if (response.body.success) {
        this.$message.success('修改成功')
        this.eventBus.$emit('filemanage-reloaddata')
      }
    },
    async addStudent () {
      const res = await this.$http.post(apis.user.isExisted, { num: this.data.num, school_id: this.currentSchool._id })
      if (res.body.existed) {
        this.$message.info('相同的编号已存在，不能重复！')
        return
      }
      this.data.school_id = this.currentSchool._id
      const response = await this.$http.post(apis.user.create, this.data)
      this.showModal = false
      if (response.body.success) {
        this.$message.success('添加成功')
        this.eventBus.$emit('filemanage-reloaddata')
      }
    },
    editCancel () {
      this.showModal = false
    },
    initForm () {
      setTimeout(() => {
        const fields = {
          name: { value: this.data.name },
          type: { value: this.data.type },
          num: { value: this.data.num },
          mobile: { value: this.data.contact_mobile }
        }
        // if (this.data.type === userTypes.student) {
        fields.group_id = { value: this.data.group_id }
        // }
        this.form.setFields(fields)
      }, 0)
    }
  },
  async created () {
    this.eventBus.$on('show-editfile-modal', this.showEditModal)
    const query = '?schoolId=' + this.currentSchool._id + '&pageSize=100000'
    const response = await this.$http.get(apis.studentGroup.list + query)
    this.classList = response.body.list
  },
  beforeDestroy () {
    this.eventBus.$off('show-editfile-modal', this.showEditModal)
  }
}
</script>
