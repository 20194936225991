<template>
 <a-modal
      :visible="isShow"
      class="customermodel1"
      width="350px"
      :centered="true"
      :footer="false"
      :closable="false"
      :maskClosable="true"
    >
      <div class="con">
        <div class="hd">
          <!-- <a-icon type="check-circle" class="icon" /> -->
          <h1>导入完成</h1>
          <p>新增<b>{{data.nInserted || 0}}</b>条，修改<b>{{data.nModified || 0}}</b>条，失败<b style="color:red;">{{data.nFailed || 0}}</b>条</p>
        </div>
        <p class="title" v-if="data.failedList && data.failedList.length">失败名单如下</p>
        <div class="list" v-if="data.failedList && data.failedList.length">
          <a-table
            :columns="columns"
            :data-source="data.failedList"
            :pagination="false"
            rowKey='index'
            size="small"
          >
          </a-table>
        </div>
        <div class="button">
          <a-button size="large" @click="onOk"
            >关闭</a-button
          >
        </div>
      </div>
    </a-modal>
</template>
<script>
const columns = [
  {
    title: '编号',
    dataIndex: 'num'
  },
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '身份',
    dataIndex: 'type'
  },
  {
    title: '部门',
    dataIndex: 'group'
  }
]
export default {
  inject: ['eventBus'],
  data () {
    return {
      columns,
      data: { nFailed: 0, failedList: [] },
      isShow: false
    }
  },
  methods: {
    ShowModal (result) {
      this.data = result || { nFailed: 0, failedList: [] }
      // if (this.data.nFailed && this.data.nFailed > this.data.failedList.length) {
      //   this.data.failedList.push({ num: `...(未列出${result.nFailed - result.failedList.length}条)` })
      // }
      // this.data.failedList.forEach((item, index) => {
      //   item.index = index
      // })
      this.isShow = true
    },
    onOk () {
      this.isShow = false
    },
    onCancel () {
      this.isShow = false
    }
  },
  created () {
    this.eventBus.$on('show-importresult-modal', this.ShowModal)
  },
  beforeDestroy () {
    this.eventBus.$off('show-importresult-modal', this.ShowModal)
  }
}
</script>
