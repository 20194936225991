<template>
<div class="filter">
  <a-row>
    <a-col :span="12">
        <div class="item" style="border-right: 1px dotted #e7e7eb;">
        <div class="title">类型</div>
        <div class="value">
          <span v-for="item in userTypes"
            :key="item"
            :class="{active:selectedUserType===item}"
            @click="filterHandler('user',item)"
          >{{item}}</span>
        </div>
      </div>
    </a-col>
    <a-col :span="12">
      <div class="item">
        <div class="title" :class="{filterTitle: isMealCard}">{{isMealCard?'账号状态':'状态'}}</div>
        <div class="value">
          <span v-for="item in enableTypes"
            :key="item"
            :class="{active:selectedEnableType===item}"
            @click="filterHandler('enable',item)"
          >{{item}}</span>
        </div>
      </div>
    </a-col>
    <a-col :span="12" v-if="isMealCard">
      <div class="item">
        <div class="title" style="width:90px">饭卡状态<a-tooltip
            slot="suffix"
            :title='statedesc'
          >
            <a-icon
              type="info-circle"
              style="margin-left:2px;"
            />
          </a-tooltip>
        </div>
        <div class="value">
         <span v-for="(item,key) in bindStates"
            :key="key"
            :class="{active:selectedBindState===key}"
            @click="filterHandler('bind',key)"
          >{{item}}</span>
        </div>
      </div>
    </a-col>
    <a-col :span="24"> <!-- :class="{filterHidden:selectedUserType!=userTypes.all&&selectedUserType!=userTypes.student}" -->
      <div class="item">
        <div class="title">部门</div>
        <div class="value">
          <span v-for="item in schoolGrades"
            :key="item"
            :class="{active:selectedGrade===item}"
            @click="filterHandler('grade',item)"
          >{{item}}</span>
        </div>
      </div>
    </a-col>
  </a-row>
</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { userTypes, enableTypes, bindStates } from '@/business/enums'
export default {
  props: {
    isMealCard: { default: false }
  },
  data () {
    return {
      userTypes,
      bindStates,
      enableTypes,
      // selectedSchoolType: '',
      selectedUserType: '',
      selectedBindState: '',
      selectedGrade: '',
      selectedEnableType: ''
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ]),
    ...mapGetters('school', [
      'schoolGrades'
    ]),
    statedesc () {
      return (
        <div class="state-desc">
          <div>
            <b>未绑定</b>
              ：用户未申请饭卡/未绑定饭卡。
          </div>
          <div>
            <b>已激活</b>
              ：用户已激活饭卡。
          </div>
          <div>
            <b>已挂失</b>
              ：用户已挂失饭卡。
          </div>
        </div>
      )
    }
  },
  methods: {
    filterHandler (type, item) {
      switch (type) {
        case 'grade':
          this.selectedGrade = item
          break
        case 'bind':
          this.selectedBindState = item
          break
        case 'user':
          this.selectedUserType = item
          break
        case 'enable':
          this.selectedEnableType = item
          break
      }
      this.$emit('filterChange')
    },
    // hasTheGrade (grade) {
    //   const result = this.gradeList.filter(item => item === grade)
    //   return !!result[0]
    // },
    init () {
      // this.selectedSchoolType = this.currentSchool.type[0]
      this.selectedUserType = this.userTypes.all
      this.selectedBindState = 'all'
      this.selectedGrade = this.schoolGrades[0]
      this.selectedEnableType = enableTypes.all
      this.$emit('filterChange')
    }
  },
  mounted () {
    this.init()
  }
}
</script>
<style scoped>
.filterHidden{
  visibility: hidden;
}
.filterTitle{
  width: 90px !important;
}
</style>
