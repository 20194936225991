<template>
 <div class="button">
    <a-button size="large" type="primary" @click="addFile">添加</a-button>
    <a-button size="large" @click="deleteFiles">删除</a-button>
    <a-button size="large" @click="exportExcel" :loading="isDownloading">导出</a-button>
    <a-upload
    name="file"
    :withCredentials="true"
    :showUploadList="false"
    accept=".xlsx"
    :action="uploadUrl"
    :beforeUpload="onBeforeUpload"
    @change="handleChange"
    :data="{school_id:currentSchool._id}"
    >
    <a-button
        size="large"
        :loading="isUploading"
    >导入</a-button>
    </a-upload>
    <import-result></import-result>
</div>
</template>
<script>
import importResult from './ImportResult.vue'
import { mapState } from 'vuex'
import moment from 'moment'
import downloadjs from 'downloadjs'
import apis from '@/business/apis'
import { all, enableTypes } from '@/business/enums'
export default {
  inject: ['eventBus'],
  components: {
    importResult
  },
  data () {
    return {
      isUploading: false,
      isDownloading: false,
      uploadUrl: apis.user.importExcel
    }
  },
  computed: {
    ...mapState('school', [
      'currentSchool'
    ])
  },
  methods: {
    handleChange ({ file }) {
      switch (file.status) {
        case 'uploading':
          this.isUploading = true
          break
        case 'done':
          this.isUploading = false
          this.handleUploadFinish(file.response)
          break
        case 'error':
          this.isUploading = false
          this.$message.error('上传失败')
          break
      }
    },
    async handleUploadFinish (result) {
      this.$message.destroy()
      if (result.err) {
        this.$message.error(result.errMsg)
        return
      }
      // this.$message.success(`导入完成，新增${result.nInserted}条，修改${result.nModified}条，失败${result.nFailed}条`)
      this.eventBus.$emit('show-importresult-modal', result)
      this.eventBus.$emit('filemanage-reloaddata')
    },
    async onBeforeUpload (file) {
      const index = file.name.search(/_\d{8}T\d{6}/)
      if (index === -1) {
        this.$message.error('文件名不符合规则，请导出最新数据进行修改，再导入，谢谢！')
        return Promise.reject(Error())
      }

      const strTime = file.name.substr(index + 1, 15)
      const response = await this.$http.get(apis.user.lastEditTime + '?schoolId=' + this.currentSchool._id)
      if (moment(strTime).valueOf() < response.body.time && file.name !== response.body.lastFile) {
        this.$message.error('数据已被更新，请导出最新数据进行修改，再导入，谢谢！', 4)
        return Promise.reject(Error())
      }

      const maxSize = 25 * 1000 * 1000
      if (file.size > maxSize) {
        this.$message.error('文件大小不可以超过25M')
        return Promise.reject(Error())
      }
      this.$message.info('正在导入...')
    },
    async exportExcel () {
      this.$message.info('正在导出...')
      this.isDownloading = true
      setTimeout(() => {
        this.isDownloading = false
      }, 1000)
      const data = { schoolId: this.currentSchool._id }
      const condition = this.$parent.filterCondition
      if (condition.grade !== all) {
        data.grade = condition.grade
      }
      // if (condition.bindState !== all) {
      //   data.state = condition.bindState
      // }
      if (condition.userType !== all) {
        data.type = condition.userType
      }
      if (condition.enableType !== all) {
        data.isDisable = condition.enableType === enableTypes.disable
      }
      if (condition.keyword) {
        data.keyword = condition.keyword
      }
      const response = await this.$http.post(apis.user.exportExcel, data, { responseType: 'blob' })
      if (!response || !response.ok) {
        this.$message.info('导出失败')
        return
      }
      downloadjs(
        await response.blob(),
        `${this.currentSchool.name}_${moment().format('YYYYMMDDTHHmmss')}.xlsx`,
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    },
    addFile () {
      this.eventBus.$emit('show-editfile-modal', { type: '警员' }, true)
    },
    deleteFiles () {
      this.$emit('onDeleteFiles')
    }
  }
}
</script>
